<template>
  <b-dropdown variant="outline-secondary" class="d-flex" ref="dropdown">
    <template slot="button-content">
      <i class="fa fa-user-friends"></i>
      {{allCount === 0 ? $t('search-tab.who-is-traveling') : `${allCount} ${$t('search-result.persons-selected')}`}}
    </template>
    <b-card href="#" class="border-0">
      <b-dropdown-item
        ><h5 class="text-center">
          <b-button variant="outline-primary" class="w-100">{{
            $t("search-tab.choose-count-select")
          }}</b-button>
        </h5></b-dropdown-item
      >
      <div class="form-group row align-items-center">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.adult") }}
          <span class="text-muted small">(12+)</span>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="adult"
            min="0"
            max="8"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.child") }}
          <p class="text-muted small">(2&ensp;to&ensp;12)</p>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="child"
            min="0"
            max="5"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.infant") }}
          <p class="text-muted small">(0&ensp;to&ensp;2)</p>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="infant"
            min="0"
            max="5"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
    </b-card>
  </b-dropdown>
</template>

<script>
import { BDropdown, BCard, BFormSpinbutton, BDropdownItem, BButton } from 'bootstrap-vue';

export default {
  name: 'Dropdown',
  components: {
    BDropdown,
    BCard,
    BFormSpinbutton,
    BDropdownItem,
    BButton,
  },
  props: {
    msg: String,
    dropdownShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  watch: {
    adult() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    child() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    infant() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    dropdownShow() {
      // if (this.dropdownShow) { this.$refs.dropdown.show(true); } else { this.$refs.dropdown.hide(true); }
    },
  },
  computed: {
    allCount() {
      return this.adult + this.child + this.infant;
    },
    adult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult
          ? this.$store.getters.GET_SEARCH_CONTENT.adult
          : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    child: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child
          ? this.$store.getters.GET_SEARCH_CONTENT.child
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    infant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant
          ? this.$store.getters.GET_SEARCH_CONTENT.infant
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
  },
  mounted() {
    const adult = (this.query.adult) ? Number(this.query.adult) : 2;
    const child = (this.query.child) ? Number(this.query.child) : 0;
    const infant = (this.query.infant) ? Number(this.query.infant) : 0;

    setTimeout(() => {
      this.adult = adult;
      this.child = child;
      this.infant = infant;
    }, 500);
  },
};
</script>

<style>
.filter-tab .dropdown .btn.dropdown-toggle {
  background-color: #fff;
  color: #212529;
  border-color: rgba(0, 0, 0, 0.2);
  padding: 0.6rem;
  font-size: 1rem;
}
.b-form-spinbutton output{
  padding: 0;
}
.b-form-spinbutton output > div, .b-form-spinbutton output > bdi{
  min-width: fit-content;
}
</style>
<style scoped>
.col-sm-4.col-form-label{
  padding: 8px;
}
.form-group .col-sm-8 {
  padding: 0;
}
</style>
